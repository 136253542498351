require('./bootstrap');
require('slick-carousel');
require('bootstrap-select');
require('bootstrap-select/dist/js/i18n/defaults-sk_SK.min')
require('magnific-popup')
import 'vanilla-cookieconsent'
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";


const slickUpper = $('.slick-upper')
const partnersSlick = $('.partners .slick-items')

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    type: 'POST',
    cache: false
});

$('select').selectpicker();

$(function () {
    if($('#appDetail').length) {
        let galleryHeight = $('.gallery').height()
        let descriptionHeight = $('.description').height()
        if(descriptionHeight < galleryHeight) {
            $('.hr-spacer').css('margin-top', (galleryHeight+80)+'px')
        }
    }
    $("#magicBtn").on("click", function () {
        $("#mesto").toggle(this.checked);
    });
    $('.gallery-item').magnificPopup({
        type: 'image',
        gallery: {
            enabled:true,
            tPrev: 'Predošlí (Ľavá šípka)',
            tNext: 'Ďalší (Práva šípka)',
            tCounter: '%curr% z %total%'
        },
        image: {
            tError: '<a href="%url%">Obrázok</a> nebolo možné načítať.'
        },
    });

    $('.magnific-gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            preload: [1,2],
            gallery: {
                enabled:true,
                tPrev: 'Predošlí (Ľavá šípka)',
                tNext: 'Ďalší (Práva šípka)',
                tCounter: '%curr% z %total%'
            },
            image: {
                tError: '<a href="%url%">Obrázok</a> nebolo možné načítať.'
            },
        });
    });
    let hash = window.location.hash
    hash = hash.replace('#', ".");
    $(hash).trigger('click');
});

$('.copy-realization').on('click touch', function () {
    let realizationHash = $(this).data('realization-hash')
    let URL = window.location.origin + '/realizacie' + realizationHash
    copyRealization($(this), URL)
})
const copyRealization = function(elem, copyText) {
    let textArea = document.createElement("textarea");
    textArea.value = copyText;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        let successful = document.execCommand("copy");
        if (successful && elem) {
            elem.html("Skopírované");
            setTimeout(function () {
                elem.html("Zdieľať");
            }, 1000);
        }
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
}

let filterProductsSection = $('#filter-products-section');
if(filterProductsSection.length && $('#appProducts').length) {
    require('./jquery.hash')
    const categoryId = filterProductsSection.data('category-id')
    $('body').on('change', '.filters select', function () {
        filterSelect($(this))
    });
    const getHash = function () {
        let hash = $.hash.getParamsObject()
        if ($.isEmptyObject(hash)) {
            return false
        } else {
            $.each(hash, function (key, value) {
                let trimedKey = key
                if(trimedKey.includes("-")) {
                    trimedKey = trimedKey.substring(0, trimedKey.indexOf('-'));
                }
                if ($.type(value) == 'string') {
                    hash[trimedKey] = decodeURI(value).split(',')
                }
            })
            return hash
        }
    }
    const filterProducts = function (remove, slug = null) {
        if(remove) {
            $.hash.remove('page')
        }
        let hash = getHash()
        let page = '';
        if(window.location.href.includes("?page=")) {
            let url = new URL(window.location.href);
            page = url.searchParams.get("page");
            page = '?page='+page;
            if(remove) {
                let newUrl = window.location.href
                newUrl = newUrl.replace(page, "");
                window.history.pushState({},"", newUrl);
                page = '';
            }
        }
        $.ajax({
            url: '/filter'+page,
            type: 'POST',
            cache: false,
            data: {hash: hash, categoryId: categoryId, slug: slug},
            dataType: 'html',
            success: function (data) {
                if (data != 'false') {
                    $("#filter-products-section").empty().html(data);
                    $('select').selectpicker('refresh');
                    reInitFilter()
                    var links = $('.hash-pagination').find('a');
                    links.each(function(link){
                        var href = $(this).attr('href');
                        $(this).attr('href', href + window.location.hash);
                    });
                } else {
                    console.log('Ajax Error in script')
                }
            },
            error: function (jqXHR, textStatus) {
                console.log('Ajax', 'Error', textStatus)
            }
        })
    }
    const checkEmptyHash = function () {
        if (getHash() === false) {
            history.pushState({}, document.title, location.href.replace('#', ''))
        }
    }
    const filterSelect = function (e) {
            e = $(e)
            let vals = e.val()
            let slug = e.data('hash')

            if(slug.includes("-")) {
                slug = slug.substring(0, slug.indexOf('-'));
            }
            if(!vals.length && slug !== 'vyrobca') {
                let top = $(window).scrollTop()
                $.hash.remove(slug)
                checkEmptyHash()
                $(window).scrollTop(top)
            } else {
                if(!vals.length && slug === 'vyrobca') {
                    $.hash.remove('vyrobca')
                } else {
                    if(slug === 'vyrobca') {
                        let hash = getHash()
                        $.each(hash, function (key,j) {
                            $.hash.remove(key)
                        })
                        $.hash.remove()
                    }
                    $.hash.set(slug, vals)
                }
            }
        filterProducts(true, slug)
    }

    const reInitFilter = function () {
        let hash = getHash()
        $('select').each(function () {
            let e = $(this)
            let hashValues = hash[e.data('hash')]
            if (hashValues) {
                e.attr('checked')
                e.val(hashValues)
            }
            e.selectpicker('refresh')
        })
    }

    $(function () {
        filterProducts(false)

        if (getHash() !== false) {
            reInitFilter()
        }
        $('body').on('click touch', '#more-products', function () {
            let moreProducts = $(this);
            if(moreProducts.hasClass('not-last')) {
                let nextPage = moreProducts.data('next-page')
                let hash = getHash()
                $.ajax({
                    url: '/next-products?page='+nextPage+window.location.hash,
                    type: 'POST',
                    cache: false,
                    data: {hash: hash, categoryId: categoryId},
                    dataType: 'html',
                    success: function (data) {
                        if (data != 'false') {
                            $(data).insertAfter($('.product').last());
                            if(moreProducts.data('last-page') == nextPage) {
                                moreProducts.addClass('d-none')
                            } else {
                                moreProducts.data('next-page', nextPage)
                            }
                            moreProducts.data('next-page', nextPage+1)
                        } else {
                            console.log('Ajax Error in script')
                        }
                    },
                    error: function (jqXHR, textStatus) {
                        console.log('Ajax', 'Error', textStatus)
                    }
                })
            }
        });
    })
}

$(function () {
    // if (slickUpper.length) {
    //     slickUpper.slick({
    //         speed: 800,
    //         autoplay: true,
    //         autoplaySpeed: 3000,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         accessibility: false,
    //         dots: false,
    //         infinite: true,
    //         arrows: false,
    //         adaptiveHeight: false,
    //         fade: true
    //     });
    // }
    if (partnersSlick.length) {
        partnersSlick.slick({
            speed: 600,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 5,
            slidesToScroll: 1,
            accessibility: false,
            dots: false,
            infinite: true,
            prevArrow: "<div class='d-flex align-items-center'><i class=\"fas fa-chevron-left\"></i></div>",
            nextArrow: "<div class='d-flex align-items-center'><i class=\"fas fa-chevron-right\"></i></div>",
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
})

let carouselUpperInner = $('#carouselUpper .carousel-inner')
let carouselUpper = $('#carouselUpper')
if(carouselUpper) {
    $( document ).ready(function() {
        setTimeout(function () {
            $('.carousel-item.active .carousel-caption').removeClass('hidden')
        }, 300)
    })
}
carouselUpper.on('slid.bs.carousel', function () {
    setTimeout(function () {
        $('.carousel-item.active .carousel-caption').removeClass('hidden')
    }, 200)
})
carouselUpper.on('slide.bs.carousel', function () {
    setTimeout(function () {
        $('.carousel-item.active .carousel-caption').addClass('hidden')
    }, 200)
})

if ($(window).width() < 768) {
    carouselUpperInner.on('touch tap click', function () {
        let link = $(this).find('.carousel-item.active a')
        if(link.length) {
            window.location.href = link.attr('href')
            return true
        }
        return false
    })
}

$('#zp-section .zp-modal').on('click touch tap', function () {
    $('input[name="product"]').val($(this).parent().find('h3').html())
})

$(window).scroll(function() {
    if ($(this).scrollTop() > 800) { //use `this`, not `document`
        $('.arrow.animated').css({
            'display': 'none'
        });
    }
});


CookieConsent.run({
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,
    manageScriptTags: true,

    cookie: {
        name: 'cc_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_gid',   // string: exact cookie name
                    }
                ]
            }
        },
        ads: {}
    },

    language: {
        default: 'sk',
        translations: {
            sk: {
                consentModal: {
                    title: 'Na vašom súkromí nám záleží\n',
                    description: 'Táto webová stránka používa nevyhnutné súbory cookie na zabezpečenie správneho fungovania a s vaším súhlasom, aj na personalizáciu obsahu našich webových stránok.',
                    acceptAllBtn: 'Súhlasím',
                    // acceptNecessaryBtn: 'Nesúhlasím',
                    showPreferencesBtn: 'Nastavenie cookies',
                    // closeIconLabel: 'Reject all and close modal',
                },
                preferencesModal: {
                    title: 'Cookies nastavenia',
                    acceptAllBtn: 'Prijať všetko',
                    acceptNecessaryBtn: 'Odmietnúť všetko',
                    savePreferencesBtn: 'Uložiť nastavenia',
                    closeIconLabel: 'Zavrieť',
                    serviceCounterLabel: 'Služba|Služby',
                    sections: [
                        {
                            title: '',
                            description: `Súbory cookie používame na zabezpečenie základných funkcií stránky a na zlepšenie vášho používateľského zážitku. Svoj súhlas pre každú kategóriu môžete kedykoľvek zmeniť.`,
                        },
                        {
                            title: 'Nevyhnutné cookies',
                            description: 'Tieto cookies sú nevyhnutné pre správne fungovanie webovej stránky. Bez týchto súborov cookies nebude webová stránka fungovať správne',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Analytické cookies',
                            description: 'Tieto cookie využívame na analyzovanie prenosu a návštevnosti na našej webovej stránke – umožňujú nám o. i. sledovať počet návštevníkov webovej stránky a ako návštevníci webovú stránku používajú. Takáto analýza nám umožňuje lepšie nastavenie webovej stránky pre zlepšenie funkčnosti stránky a zlepšenie užívateľskej spokojnosti. Tieto cookie môžu byť použité len s Vaším predchádzajúcim súhlasom.',
                            linkedCategory: 'analytics',
                        },
                        {
                            title: 'Marketingové cookies',
                            description: 'Vďaka marketingovým cookies bude pre Vás používanie nášho webu pohodlnejšie a prispôsobené Vašim preferenciám. Rovnako uvidíte viac ponuky, ktoré Vás môžu zaujímať a menej nerelevantnej reklamy.',
                            linkedCategory: 'ads',
                        }
                    ]
                }
            },
            cs: {
                consentModal: {
                    title: 'Na vašem soukromí nám záleží\n',
                    description: 'Tato webová stránka používá nevyhnutelné soubory cookie na zabezpečení správného fungování a s vaším souhlasem i na personalizaci obsahu našich webových stránek.',
                    acceptAllBtn: 'Souhlasím',
                    acceptNecessaryBtn: 'Nesouhlasím',
                    showPreferencesBtn: 'Nastavení cookies',
                    // closeIconLabel: 'Reject all and close modal',
                },
                preferencesModal: {
                    title: 'Cookies nastavení',
                    acceptAllBtn: 'Přijmout vše',
                    acceptNecessaryBtn: 'Zamítnout vše',
                    savePreferencesBtn: 'Uložit nastavení',
                    closeIconLabel: 'Zavřít',
                    serviceCounterLabel: 'Služba|Služby',
                    sections: [
                        {
                            title: '',
                            description: `Soubory cookie používáme k zajištění základních funkcí stránky a ke zlepšení vašeho uživatelského zážitku. Svůj souhlas pro každou kategorii můžete kdykoli změnit.`,
                        },
                        {
                            title: 'Nevyhnutelné cookies',
                            description: 'Tyto cookies jsou nevyhnutelné pro správné fungování webové stránky. Bez těchto souborů cookies nebude webová stránka fungovat správně.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Analytické cookies',
                            description: 'Tyto cookies využíváme na analyzování přenosu a návštěvnosti na naší webové stránce – umožňují nám i sledovat počet návštěvníků webové stránky a jak návštěvníci webovou stránku používají. Tato analýza nám umožňuje lepší nastavení webové stránky pro zlepšení funkčnosti stránky a zlepšení uživatelské spokojenosti. Tyto cookies můžou být použité jen s Vaším předchozím souhlasem.',
                            linkedCategory: 'analytics',
                        },
                        {
                            title: 'Marketingové cookies',
                            description: 'Díky marketingovým cookies bude pro Vás používání našeho webu pohodlnější a přizpůsobené Vašim preferencím. Také uvidíte více nabídek, které Vás mohou zajímat a méně nerelevantní reklamy.',
                            linkedCategory: 'ads',
                        }
                    ]
                }
            }
        },
    }
});


const form = document.querySelector('#form');

function submitForm(e) {
    e.preventDefault();

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'form_sent'
    });
    form.submit()
    // console.log(dataLayer)
}
if(form) {
    form.addEventListener('submit', submitForm);
}
